import React from "react";

import PropTypes from 'prop-types'

export default function WifeSchema({ housewife: { fullName } }) {
    const scriptContent = `
        {
        "@context": "http://schema.org/",
        "@type": "Person",
        "name": "${fullName}"
        }
    `;

    return (
        <script type="application/ld+json" dangerouslySetInnerHTML={{__html: scriptContent }}></script>
    );
}

WifeSchema.propTypes = {
    housewife: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
    })
}