import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import "./Header.scss";

export default class Header extends React.PureComponent {
    render() {
        let image = '';
        if(this.props.housewife.image) {
            image = <img src={ this.props.housewife.image } className="wife-header-image" alt={ `${this.props.housewife.fullName}` }/>;
        }

        return (
            <div className={'wife-header-holder ' + (image === '' ? 'wife-header-holder--no-image' : '') }>
                { image }
                <div className={ 'wife-rank hw-color-bg--' + this.props.franchise.slug }>
                    { this.props.housewife.rank }
                </div>
                <h2 className='wife-header-wife'>
                    { this.props.housewife.fullName }
                </h2>
                <p>
                    { this.props.housewife.firstName } ranks <strong>{ this.props.housewife.rank }</strong> out of <Link to="/wives"><strong>{ this.props.totalWives }</strong> total housewives</Link>.
                </p>
            </div>
        );
    }
}

Header.propTypes = {
    housewife: PropTypes.shape({
        image: PropTypes.string,
        firstName: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        rank: PropTypes.number.isRequired
    }),
    franchise: PropTypes.shape({
        city: PropTypes.string.isRequired,
        acronym: PropTypes.string,
        slug: PropTypes.string
    }),
    totalWives: PropTypes.number.isRequired
}