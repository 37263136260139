import React from "react";
import { graphql } from "gatsby";
import {Helmet} from "react-helmet";

import DefaultLayout from "../components/layouts/default";
import FranchiseIcon from "../components/franchiseicon/FranchiseIcon";
import Header from "../components/layouts/header/Header";
import WifeHeader from "../components/layouts/wife/Header";
import TaglineList from '../components/taglinelist/TaglineList';
import WifeSchema from '../components/schemaorg/WifeSchema';

import transformTaglines from "../utils/transform-tagline";

export default (props) => {
    let taglines = transformTaglines(props.data.realTaglines.taglines)
                        .slice().reverse();
    
    let wife = taglines[0].node.housewife;
    let franchise = taglines[0].node.seasonList.edges[0].node.franchise;

    return <DefaultLayout>
        <Helmet>
            <title>{ wife.fullName }'s Complete Tagline Ranking!</title>
        </Helmet>
        <Header headerIcon={ <FranchiseIcon franchise={ franchise } /> }>
            <WifeSchema housewife={ wife } />
            <WifeHeader housewife={ wife } franchise={ franchise } totalWives={ props.pageContext.totalWives } />
        </Header>
        <TaglineList taglines={ taglines } showImages={ false } />
    </DefaultLayout>
}

export const query = graphql`
    query($slug: String) {
        realTaglines {
            taglines(housewife_Slug: $slug) {
                edges {
                    node {
                        id
                        tagline
                        rank
                        slug
                        housewife {
                            firstName
                            fullName
                            rank
                            slug
                            housewifeimageSet {
                                crops {
                                    height
                                    name
                                    url
                                    width
                                }
                            }
                        }
                        seasonList:season {
                            edges {
                                node {
                                    number
                                    franchise {
                                        city
                                        slug
                                    }
                                }
                            }
                        }
                        taglinescore {
                            total
                        }
                    }
                }
            }
        }
    }
`